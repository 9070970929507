import React, { useState } from 'react';
import { Typography, Divider, List, ListItem, ListItemText, Button, Chip, Box, Accordion, AccordionSummary, AccordionDetails, AccordionActions, Hidden } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import EditIcon from '@material-ui/icons/Edit';
import StarIcon from '@material-ui/icons/Star';
import utils from '../../../utils';
import CompCadastroProduto from "./compCadastroProduto";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import AuthService from '../../../AuthService';
import { withSnackbar } from 'notistack';

const CompListaGrupo = ({ grupos, handlePauseGrupo, handlePauseProduto, handleEditarGrupo, saveProdutosBuscados, handleUpdateGrupo, duplicarProduto, ativos, produtosBuscados, enqueueSnackbar }) => {
    const [expanded, setExpanded] = useState(false)
    const [openCadProd, setOpenCadProd] = useState(false)
    const [produtoEdit, setProdutoEdit] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const handleEditProduct = (product) => {
        
        if(!!product?.id && product?.id > 0){
            setLoading(true)
            handleListProdutos(product.id).then((produtoCompleto)=>{
                setProdutoEdit(produtoCompleto)
                handleTogleOpenCadProd()
            }).catch((err)=>{
                if (err.response === undefined) {
                    enqueueSnackbar('Falha ao listar grupo', { variant: 'error' })
                  } else {
                    err.response.json().then(elem => {
                      enqueueSnackbar('Falha ao listar grupo' + elem.errorMessage, { variant: 'error' })
                    })
                  }
            }).finally(()=>{
                setLoading(false)
            })
        }
    }

    const handleTogleOpenCadProd = () =>{
        setOpenCadProd(!openCadProd)
    }

    const handleChangeProduto = (produto, mudanca, grupoAntigo) => {
        let index = grupos.findIndex(el => el.grupoProduto.id === produto?.grupoProduto?.id)
        let indexProd = grupos[index]?.produtos?.findIndex(el => el.id === produto.id)

        if (mudanca === "SALVAR") {
            
            if (produtosBuscados) {
                let indexBuscado = produtosBuscados.findIndex(el => el.id === produto.id)
                produtosBuscados[indexBuscado] = produto
                saveProdutosBuscados(produtosBuscados)
            }

            // Deletar produto do grupo antigo
            if (grupoAntigo && grupoAntigo.id !== produto.grupoProduto.id) {
                let indexGpAntigo = grupos.findIndex(el => el.grupoProduto.id === grupoAntigo.id)
                let indexProdGpAntigo = grupos[indexGpAntigo].produtos.findIndex(el => el.id === produto.id)
                grupos[indexGpAntigo].produtos.splice(indexProdGpAntigo, 1)
            }

            if (indexProd > -1) {
                grupos[index].produtos[indexProd] = produto
            } else {
                grupos[index].produtos.push(produto)
            }
        } else if (mudanca === "EXCLUIR") {
            
            if (produtosBuscados) {
                let indexBuscado = produtosBuscados.findIndex(el => el.id === produto.id)
                produtosBuscados.splice(indexBuscado, 1)
                saveProdutosBuscados(produtosBuscados)
            }
            if (indexProd > -1) {
                grupos[index].produtos.splice(indexProd, 1)
            }
        }

        let idxGrupoPromo = grupos.findIndex(el => el.grupoProduto.id === -1)
        let idxProdPromo = grupos[idxGrupoPromo].produtos.findIndex(prod => prod.id === produto.id)

        if (mudanca === 'SALVAR') {
            if (idxProdPromo >= 0) {
                if (produto.promocaoAtiva) {
                    grupos[idxGrupoPromo].produtos[idxProdPromo] = produto
                } else {
                    grupos[idxGrupoPromo].produtos.splice(idxProdPromo, 1)
                }
            } else {
                if (produto.promocaoAtiva) {
                    grupos[idxGrupoPromo].produtos.push(produto)
                }
            }
        } else if (mudanca === 'EXCLUIR') {
            if (idxProdPromo >= 0) {
                grupos[idxGrupoPromo].produtos.splice(idxProdPromo, 1)
            }
        }

        handleUpdateGrupo(grupos)
    }

    const newProduct = (grupo) => {
        const produto = {
            descricao: "",
            nome: "",
            fichaTecnica: "",
            localImpressao: 'CAIXA',
            pausado: false,
            localVenda: "TODOS",
            preco: 0.0,
            grupoProduto: { id: grupo.id },
            complementos: [],
            ativo: true,
            advertencia: "",
            codigoExterno: "",
        }

        setProdutoEdit(produto)
        console.log(produtoEdit)
    }

    const handleAtivarGrupo = (grupo, event) => {
        event.stopPropagation()

        let service = new AuthService()
        let json = { id: grupo.id, ativo: true }

        service.put("/grupoProduto/changeOnly", json)
            .then(resp => {
                let index = grupos.findIndex(el => el.grupoProduto.id === resp.id)
                grupos[index].grupoProduto = resp
                handleUpdateGrupo(grupos)
                enqueueSnackbar('Grupo ativado com sucesso', { variant: 'success' })
            })
            .catch(err => {
                enqueueSnackbar('Erro ao ativar grupo', { variant: 'error' })
            })
    }

    function handleListProdutos(produto) {
        let service = new AuthService()
        return service.get(`/produto/${produto}`)
    }

    return (
        <div>
            {openCadProd && (
                <CompCadastroProduto
                    key={produtoEdit ? produtoEdit.id : null}
                    open={openCadProd}
                    loading={loading}
                    produto={produtoEdit}
                    handleChangeProduto={handleChangeProduto}
                    handleOpenCadProd={handleTogleOpenCadProd}
                />
            )}

            {grupos.map(grupo => (
                <React.Fragment key={grupo.grupoProduto.id}>
                    {(grupo.produtos.length === 0 && grupo.grupoProduto.id === -1) ? null : (
                        (grupo.grupoProduto.ativo === ativos) && (
                            <Box key={grupo.id} m={1}>
                                <Accordion expanded={expanded === grupo.grupoProduto.id} onChange={handleChange(grupo.grupoProduto.id)}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={grupo.grupoProduto.id + "-content"}>
                                        <Box display="flex" style={{ width: '100%' }}>
                                            <Box flexGrow={1}>
                                                <Typography variant="h6" color="primary">
                                                    {grupo.grupoProduto.descricao ? grupo.grupoProduto.descricao.toUpperCase() : ""}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                {grupo.grupoProduto.id !== -1 ? (
                                                    grupo.grupoProduto.ativo ? (
                                                        <>
                                                            <Button
                                                                startIcon={grupo.grupoProduto.pausado ? <PlayCircleOutlineIcon /> : <PauseCircleOutlineIcon />}
                                                                color={grupo.grupoProduto.pausado ? "secondary" : "primary"}
                                                                onClick={(event) => handlePauseGrupo(event, grupo)}
                                                            >
                                                                <Hidden smDown>{grupo.grupoProduto.pausado ? 'Iniciar vendas' : 'Pausar vendas'}</Hidden>
                                                            </Button>
                                                            <Button startIcon={<EditIcon />} onClick={(event) => handleEditarGrupo(grupo, event)}>
                                                                <Hidden smDown>Editar</Hidden>
                                                            </Button>
                                                        </>
                                                    ) : (
                                                        <Button startIcon={<CheckCircleOutlineIcon />} onClick={(event) => handleAtivarGrupo(grupo.grupoProduto, event)}>
                                                            <Hidden smDown>Ativar</Hidden>
                                                        </Button>
                                                    )
                                                ) : null}
                                            </Box>
                                        </Box>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <List style={{ width: "100%" }}>
                                            {grupo.produtos.map(produto => (
                                                <React.Fragment key={produto.id}>
                                                    <ListItem>
                                                        <ListItemText
                                                            primary={
                                                                <React.Fragment>
                                                                    <Typography color={"primary"} component="span"
                                                                        style={{ fontWeight: "400", fontFamily: "Bebas Neue", textTransform: "capitalize", fontSize: "135%" }}>
                                                                        {produto.nome}
                                                                        {produto.promocaoAtiva ? (
                                                                            <Chip color="primary" size="small" label="promo" icon={<StarIcon />} style={{ marginLeft: "15px" }} />
                                                                        ) : null}
                                                                    </Typography>
                                                                </React.Fragment>
                                                            }
                                                        />
                                                        <Typography>
                                                            {utils.formatNumber(produto.preco)}
                                                        </Typography>

                                                        <Button disabled={!grupo.grupoProduto.ativo} edge="end" color={produto.pausado ? "secondary" : "primary"} onClick={(event) => handlePauseProduto(event, produto, grupo)}>
                                                            {produto.pausado ? <PlayCircleOutlineIcon /> : <PauseCircleOutlineIcon />}
                                                        </Button>

                                                        <Button title="Editar produto" disabled={!grupo.grupoProduto.ativo} onClick={() => { handleEditProduct({ ...produto })}}>
                                                            <EditIcon style={{ width: "20px" }} />
                                                        </Button>

                                                        {grupo.grupoProduto.id !== -1 && (
                                                            <Button title="Criar uma cópia deste produto" onClick={() => duplicarProduto(produto, grupo)}>
                                                                <FileCopyOutlinedIcon style={{ width: "20px" }} />
                                                            </Button>
                                                        )}
                                                    </ListItem>
                                                    <Divider />
                                                </React.Fragment>
                                            ))}
                                        </List>
                                    </AccordionDetails>

                                    <AccordionActions>
                                        {grupo.grupoProduto.id !== -1 && (
                                            <Button variant="contained" color="primary" disabled={!grupo.grupoProduto.ativo} onClick={() => { newProduct(grupo.grupoProduto); handleTogleOpenCadProd() }}>
                                                Novo Produto
                                            </Button>
                                        )}
                                    </AccordionActions>
                                </Accordion>
                            </Box>
                        )
                    )}
                </React.Fragment>
            ))}
        </div>
    )
}

export default withSnackbar(CompListaGrupo)
